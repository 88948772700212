import directive from './directive';
import './styles.scss';

let moduleName = 'forms-common.formio-custom.kbs-formio-wizard';

angular
  .module(moduleName, [])
  .directive('kbsFormioWizard', directive);

export default moduleName;
