import angular from 'angular';
import 'ng-rollbar';
import get from 'lodash/get';
import some from 'lodash/some';

export const rollbarModule = angular.module('rollbar', ['tandibar/ng-rollbar']);

rollbarModule
  .config(function(RollbarProvider, $windowProvider) {
    'ngInject';
    let $window = $windowProvider.$get();
    $window.__config.rollbar.payload.client = {
      javascript: {
        source_map_enabled: true,
        code_version: APP_VERSION,
        guess_uncaught_frames: true
      }
    };
    RollbarProvider.init($window.__config.rollbar);
    if (!_.get($window, '__config.rollbar.accessToken.length')) {
      RollbarProvider.deinit();
    }
  })
  .run(Rollbar => {
    Rollbar.configure({
      /**
       * Add a list of regular expressions to avoid sending false noisy errors
       */
      checkIgnore: function(isUncaught, args, payload) {
        const blacklistRegexp = [];
        if (isUncaught) {
          const messageBody = get(payload, 'body.trace.exception.message', '');
          if (some(blacklistRegexp, regexp => regexp.test(messageBody))) {
            return true;
          }
        }
        return false;
      }
    });
  });

export default rollbarModule.name;
