import angular from 'angular';

import 'ui-select/dist/select.css';
import './common.scss';
import FormSubmitService from './services/form-submit.service';
import ServiceErrorModule from './providers/service-error';
import FormioWizard from './formio-wizard';

import './common.scss';

let commonModule = angular.module('app.common', [
  FormSubmitService,
  ServiceErrorModule,
  FormioWizard
]);

export default commonModule.name;
