import _ from 'lodash';
import FormioUtils from 'formiojs/utils';
export default class FormSubmitSurveyController {
  constructor(
    $rootScope,
    $scope,
    $stateParams,
    $state,
    $window,
    $timeout,
    $location,
    $geolocation,
    formSubmitSurveyService,
    AppConfig,
    appConfig,
    Rollbar,
    UtilsService,
    Formio
  ) {
    'ngInject';
    this.scope = $scope;
    this.rootScope = $rootScope;
    this.state = $state;
    this.formSubmitSurveyService = formSubmitSurveyService;
    this.AppConfig = AppConfig;
    this.sharedConfig = appConfig;
    this.stateParams = $stateParams;
    this.window = $window;
    this.timeout = $timeout;
    this.location = $location;
    this.$geolocation = $geolocation;
    this.submitter = { email: $stateParams.email, customer: $stateParams.customer };
    this.Rollbar = Rollbar;
    this.UtilsService = UtilsService;
    this.Formio = Formio;

    if ($stateParams.formId) {
      this.loadSurvey();
    } else {
      this.redirectInactive('The url does not belong to a survey.');
    }

    $scope.$on('formSubmit', (event, submission) => {
      event.preventDefault();
      this.submission = submission;
      this.Rollbar.debug('Survey submit: Submit survey', { submission });
      this.save();
    });

    $scope.$on('formLoad', (event, form) => {
      this.UtilsService.fixFormCheckboxLabel(form);
      this.UtilsService.addGmapsKeys({ form, key: this.window.__config.googleMapApiKey });
      this.survey.template = form;
    });

    $scope.$on('locationSet', (event, info) => {
      this.selectedLocation = _.find(
        this.submission.data.formMeta.locations,
        { customer_id: info.changed.value.customer_id }
      );
    });
    $scope.$on('siteSet', (event, site) => {
      this.selectedLocation = site;
    });
  }

  loadSurvey() {
    this.formSubmitSurveyService
      .getSurvey(this.stateParams.formId)
      .then(response => {
        const { form } = response;
        this.Formio.setUser(form.formioCredentials.user);
        this.Formio.setToken(form.formioCredentials.token);
        this.formUrl = `${form.formioCredentials.project_url}/form/${form.template._id}`;
        this.survey = form;
        this.survey.template = this.getFormDefinition(form.template);
        this.getClientCoordinates('initial').then(coordinates => {
          this.initialCoordinates = coordinates;
        }).catch(e => {
          if (form.restrictGeolocation)
            this.redirectInactive('Geo location is required to submit the survey. Please Enable it and refresh the page.');
        });

        this.window.gtag('set', 'page', '/surveys-form/display');
        this.window.gtag('event', 'pageview');
        const paramsData = _.omit(this.location.search(), 'email', 'customer');
        this.submission = {
          data: {
            ...paramsData,
            ...response.form.initialSubmissionData
          }
        };
        this.fixSignatureWidth();
      })
      .catch(error => {
        this.Rollbar.error('Error loading survey', error);

        if (error.status === 400) {
          this.redirectInactive(error.data.message);
          return;
        }

        this.redirectInactive('Survey is no longer available.');
      });
  }

  submitFormio4() {
    return this.getClientCoordinates('final')
      .then(coordinates => {
        return this.save(coordinates);
      })
      .catch(e => {
        return this.save();
      });

  }

  getCustomerMetadata(location) {
    if (location) {
      return {
        id: _.get(location, 'parent_group.legacy_src_id'),
        zip: location.post_code,
        city: location.city,
        name: location.name,
        state: location.state,
        address: location.address_1,
        storeId: location.store_num,
        store_number: location.customer_id,
        geoLocation: [
          _.get(location, 'geo_location.lat', null),
          _.get(location, 'geo_location.long', null)
        ],
        // Field manager app specific
        customerId: location.customer_id,
        zoneSupervisor: this.getZoneSupervisor(location)
      };
    } else {
      return {};
    }
  }

  getZoneSupervisor(location) {
    const managers = _.get(location, 'managers');
    const zoneSupervisor = find(managers, ['role', 'Zone Supervisor']);
    return zoneSupervisor ? zoneSupervisor : { first_name: '', last_name: '' };
  }

  save(coordinates) {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    return this.formSubmitSurveyService
      .submit({
        formioFormId: this.survey.template._id,
        formVersionId: this.survey.formVersionId,
        form_id: this.survey._id,
        submission: {
          ..._.omit(this.submission, 'data.formMeta.locations'),
          coordinates: {
            initialCoordinates: this.initialCoordinates,
            finalCoordinates: coordinates
          },
          metadata: {
            customer: this.getCustomerMetadata(this.selectedLocation),
            submissionLanguage: localStorage.getItem('PreferredLanguageKey') || 'en'
          }
        },
        submitter: this.submitter
      })
      .then(() => {
        this.showSuccess = true;
        this.redirect();
        this.window.gtag('set', 'page', '/surveys-form/submitted');
        this.window.gtag('event', 'pageview');
      })
      .catch(error => {
        this.Rollbar.error('Survey submit: Submit fails', {
          error,
          survey: this.survey,
          submission: this.submission,
          submitter: this.submitter
        });
        if (error.status === 404) {
          this.redirectInactive('Survey is no longer available.');
        }
        this.rootScope.kbsAlerts = [
          { type: 'danger', message: error.data.message || 'Form submission error' }
        ];
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  redirect() {
    this.timeout(() => {
      this.window.location.href = this.sharedConfig.surveyRedirectUrl;
    }, 30000);
  }

  redirectInactive(message) {
    this.showFallbackMessage = message;
    this.redirect();
  }

  surveyLegend() {
    return this.submitter.email + (this.submitter.customer ? `, ${this.submitter.customer}` : '');
  }

  closeAlert(index) {
    this.rootScope.kbsAlerts.splice(index, 1);
  }

  fixSignatureWidth() {
    setInterval(() => {
      document.querySelectorAll('canvas[signature]').forEach(e => {
        const parentWidth = `${e.parentElement.offsetWidth - 10}px`;
        if (parseInt(e.getAttribute('width')) < 10) {
          e.setAttribute('width', parentWidth);
          e.style.backgroundColor = '#f5f5eb';
        }
      });
    });
  }

  getClientCoordinates(initialOrFinalMoment) {
    const GEOLOCATION_CONFIGURATION_PARAMS = {
      enableHighAccuracy: true,
      maximumAge: 60 * 1000,
      timeout: 40 * 1000,
    };

    return this.$geolocation.getCurrentPosition(GEOLOCATION_CONFIGURATION_PARAMS).then(position => {
      let geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      return geolocation;
    }).catch(e => {
      this.Rollbar.error(`Error getting ${initialOrFinalMoment} geolocation`, e);
      throw e;
    });
  }

  getFormDefinition(form) {
    const submitComponent = FormioUtils.getComponent(form.components, 'submit');
    if (!submitComponent) {
      form.components.push({
        "input": true,
        "label": "submit",
        "tableView": false,
        "key": "submit",
        "size": "md",
        "leftIcon": "",
        "rightIcon": "",
        "block": false,
        "action": "submit",
        "disableOnInvalid": false,
        "theme": "primary",
        "type": "button"
      })
    } else {
      submitComponent.label = 'submit';
    }
    const prepare = component => {
      // removeAutoFocus
      component.autofocus = false;
      if (component.type === 'file') {
        component.url = this.formUrl;
      }
      if (component.type === 'address') {
        _.set(component, 'map.key', this.window.__config.googleMapApiKey);
      }
    };
    const incluAll = true;
    FormioUtils.eachComponent(form.components, prepare, incluAll);
    return form;
  }
}
