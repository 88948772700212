import { openSlides } from '@kbs/formiojs-custom';

const ERROR_CONTAINER_CLASS = 'alert-danger';
const ALERT_CLASS = 'alert';
const WIZARD_SUBMIT_BUTTON_CLASS = 'btn-wizard-nav-submit';
const WIZARD_DISPLAY = 'wizard';
const FORM_DISPLAY = 'form';
const PDF_DISPLAY = 'pdf';
const VALIDATION_ERROR = 'ValidationError';
const ERROR_MESSAGE = 'Please fix the following errors before submitting.';
const FROMIO_ELEMENT_ID = 'formio4';

const link = async (scope, element, attributes) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('frameborder', '1');
  const navBar = document.querySelector('.navbar-header');
  const formTitle = document.querySelector('.form-submit-title-text');
  function setIframeSize() {
    const container = document.querySelector('.survey-panel');
    if (container) {
        let height = window.innerHeight - _.get(navBar, 'scrollHeight', 0) - _.get(formTitle, 'scrollHeight', 0) - 20;
        iframe.setAttribute('width', container.offsetWidth);
        iframe.setAttribute('height', height);
    }
  }
  setInterval(setIframeSize, 100);

  iframe.src = 'formio4.html';
  iframe.style.border = 'none';

  let options = {
    element: iframe,
    formioOptions: {
      hooks: {
        beforeCancel: () => false
      },
      i18n: scope.translations,
      baseURL: '/'
    },
    ...scope
  }

  function setCancelButton(lang) {
    let submitButton, cancelButton;
    const dic = {
      en: {
        submit: 'SUBMIT',
        cancel: 'CANCEL'
      },
      es: {
        submit: 'ENVIAR',
        cancel: 'CANCELAR'
      }
    }
    cancelButton = iframe.contentWindow.document.createElement('a');
    submitButton = iframe.contentWindow.document.getElementsByClassName('formio-component-submit')[0];
    submitButton.childNodes[1].innerText = dic[lang].submit;
    submitButton.childNodes[1].classList.add('button');
    submitButton.childNodes[1].classList.add('submit-button');
    cancelButton.classList.add('button');
    cancelButton.innerText = dic[lang].cancel;
    submitButton.appendChild(cancelButton);
    cancelButton.classList.add('btn', 'cancel-submit-button', 'cancel-submit');
    cancelButton.addEventListener('click', () => scope.onCancel());
  }

  function disbleSubmitButton() {
    setTimeout(() => {
      if (scope.form.display === WIZARD_DISPLAY) {
        const [submitButton] = iframe.contentWindow.document.getElementsByClassName(WIZARD_SUBMIT_BUTTON_CLASS);
        if (submitButton)
          submitButton.setAttribute('disabled', '');
      }
    });
  }

  function enableSubmitButton() {
    setTimeout(() => {
      if (scope.form.display === WIZARD_DISPLAY) {
        const [submitButton] = iframe.contentWindow.document.getElementsByClassName(WIZARD_SUBMIT_BUTTON_CLASS);
        if (submitButton)
          submitButton.removeAttribute('disabled');
      }
    });
  }

  function createErrorContainer(errors) {
    const alert = iframe.contentWindow.document.createElement('div');
    const errorMessage = iframe.contentWindow.document.createElement('p');
    const list = iframe.contentWindow.document.createElement('ul');
    errorMessage.textContent = ERROR_MESSAGE;
    alert.classList.add(ALERT_CLASS, ERROR_CONTAINER_CLASS);
    alert.appendChild(errorMessage);
    alert.appendChild(list);
    errors.forEach(error => {
      const listItem = iframe.contentWindow.document.createElement('li');
      const span = iframe.contentWindow.document.createElement('span');
      span.textContent = error.message;
      list.appendChild(listItem);
      listItem.appendChild(span);
    });
    const formioElement = iframe.contentWindow.document.getElementById(FROMIO_ELEMENT_ID);
    formioElement.prepend(alert);
  };

  iframe.onload = async function() {
    options.element = iframe.contentWindow.document.getElementById('formio4');
    options.element.classList.add('formio-form-custom-theme');
    let formioLoaded = false;
    while (!formioLoaded) {
      formioLoaded = !!_.get(iframe, 'contentWindow.createFormRenderer');
    }

    const formio = await iframe.contentWindow.createFormRenderer(options);
    await formio.formReady;
    const lang = localStorage.getItem('PreferredLanguageKey') || 'en';
    formio.language = lang;
    if (scope.form.display === FORM_DISPLAY || scope.form.display === PDF_DISPLAY ) {
      setCancelButton(lang);
      iframe.contentWindow.document.body.classList.add('single-page-form');
    } else {
      iframe.contentWindow.document.body.classList.add('multi-page-form');
      formio.on('wizardCancel', () => scope.onCancel());
    }

    scope.$on('formLanguageChanged', (event, lang) => {
      formio.language = lang;
      setCancelButton(lang);
    })
    scope.onFormioLoad({ params: { form: scope.form }});
    formio.on('submit', (submission, saved) => {
      disbleSubmitButton();
      return scope.onFormioSubmit({ params: submission })
        .catch(error => {
          if (error.name === VALIDATION_ERROR) {
            const errors = error.details.map(error => ({
              ...error,
              component: error.context
            }))
            createErrorContainer(errors);
            formio.emit('error', errors);
          }
        });
    });
    formio.on('submitError', errors => {
      enableSubmitButton();
    });
    formio.on('error', errors => {
      enableSubmitButton();
    });
    formio.on('openSlides', ({ index, images, elements }) => openSlides(index, images, elements));
    formio.on('change', function(event, a, b, c) {
      // Called for every component.
      if (event.changed) {
        if ('locationSelect' === event.changed.component.type){
          scope.$emit('locationSet', event);
        } else if ('kbssiteselect' === event.changed.component.type) {
          scope.$emit('siteSet', event.changed.value);
        }
      }
    });
  }

  scope.$watch(
    () => {
      if (
        iframe.contentWindow &&
        iframe.contentWindow.document &&
        iframe.contentWindow.document.body
      )
        return iframe.contentWindow.document.body.scrollHeight;
    },
    setIframeSize,
    true
  );

  element.append(iframe);
}

const formio4 = angular.module('app.common.directives.formio4', []);
formio4.directive('formio4', () => ({
  scope: {
    form: '=',
    submission: '=',
    options: '=',
    endpoint: '=',
    translations: '<',
    onFormioLoad: '&',
    onFormioSubmit: '&',
    onCancel: '&'
  },
  restrict: 'EA',
  link
}));

export default formio4.name;
