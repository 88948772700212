export default ['$timeout', function ($timeout) {
  return {
    link: function (scope, element, attr) {
      let $element = $(element);

      $element.addClass('kbs-formio-wizard');
      $timeout(() => {
        $element.delegate('.bs-wizard-step', 'click', function (e) {
          let $step = $(e.currentTarget);

          if (!$step.hasClass('active')) {
            angular.element(e.currentTarget).find('.bs-wizard-dot').click();
          } else {
            $element.toggleClass('kbs-show-wizard-steps');
          }
        });

        $element.delegate('.bs-wizard-dot', 'click', function (e) {
          $element.toggleClass('kbs-show-wizard-steps');
          return false;
        });
      });
    }
  };
}];
