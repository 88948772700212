function formSubmitService($http, Rollbar) {
  'ngInject';
  function submit(data){
    return $http({
      url: '/api/submissions/',
      method: 'POST',
      data: data
    })
    .then((response) => {
      Rollbar.debug('Form submit: Getting submissions from server', data);
      return response.data;
    });
  }

  function getAudit(auditId, customerId) {
    return $http({
      url: `/api/audits/${auditId}/${customerId}`,
      method: 'GET'
    })
    .then((response) => {
      Rollbar.debug('Form submit: Getting audit detail', {auditId, customerId});
      return response.data;
    });
  }

  function getAuditById(auditId) {
    return $http({
      url: `/api/audits/${auditId}`,
      method: 'GET'
    })
    .then((response) => {
      return response.data;
    });
  }

  return {submit, getAudit, getAuditById};
}

export default angular.module('app.common.services.form-submit.service', [])
.service('formSubmitService', formSubmitService)
.name;
