export class ServiceError extends Error {
  constructor(error) {
    // TODO: Normalize msg property to 'message'
    let dataMsg = _.get(error, 'data.msg') || _.get(error, 'data.message');
    let message = dataMsg ? `Service Error - ${dataMsg}` : 'Service Error';
    super(message);
    this.message = message;
    this.name = this.constructor.name;

    this.errors =  _.chain(error).get('data.errors').values().map(e => Object.assign(e, {status: error.status})).value() || [];
    if(dataMsg) {
      this.errors.push({
        message: dataMsg,
        status: error.status
      });
    };
  }
}

function omitResponseExtras(response) {
  return _.omit(response, 'config.headers', 'config.transformRequest', 'config.transformResponse');
}

export function interceptorConfiguration($httpProvider) {
  'ngInject';

  $httpProvider.interceptors.push(function($q, Rollbar) {
    'ngInject';
    return {
      responseError: function(response) {
        let cleanResponse = omitResponseExtras(response);
        if(response.status >= 500 <= 599) {
          Rollbar.error('Service operation error', cleanResponse);
        } else {
          Rollbar.warning('Service operation rejected', cleanResponse);
        }
        return $q.reject(response);
      }
    };
  });
}

export const module = angular.module('app.common.providers.service-error', [])
  .config(interceptorConfiguration);

export default module.name;