import formSubmitSurveyService from './form-submit-survey.service';
import FormSubmitSurveyComponent from './form-submit-survey.component';
import LanguageSelector from '@kbs/kbs-forms-common/lib/language-selector/language-selector';
import SubmitSurveyV4 from './form-submit-survey-v4';

export const module = angular.module('app.components.form-submit-survey', [LanguageSelector, SubmitSurveyV4])
  .service('formSubmitSurveyService', formSubmitSurveyService)
  .component('formSubmitSurvey', FormSubmitSurveyComponent)
  .config(function ($stateProvider) {
    'ngInject';
    $stateProvider
      .state('survey', {
      	url: '/:formId?email&customer',
        component: 'formSubmitSurvey'
      });
  })
  .run(formioComponents => {
    'ngInject';
    formioComponents.components.image = { ...formioComponents.components.file };
    formioComponents.components.file.settings.image = true;
    formioComponents.components.file.settings.storage = 's3';
    formioComponents.components.file.settings.slides = true;
    formioComponents.components.file.settings.multiple = true;
  });

export default module.name;
