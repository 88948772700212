import angular from 'angular';

import FormSubmitSurvey from './form-submit-survey/form-submit-survey';


let componentsModule = angular.module('app.components', [
  FormSubmitSurvey
]);

export default componentsModule.name;
