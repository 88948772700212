export default function formSubmitSurveyService($http, $stateParams) {
  'ngInject';
  function submit(data){
    return $http({
      url: `/api/survey-forms/${$stateParams.formId}/submissions`,
      method: 'POST',
      data: data
    })
    .then((response) => response.data);
  }

  function getSurvey(surveyId) {
    return $http({
      url: `/api/survey-forms/${surveyId}`,
      method: 'GET'
    })
    .then((response) => response.data);
  }

  return { submit, getSurvey };

}