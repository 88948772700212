import angular from 'angular';

export const RollbarToConsoleModule = angular.module('app.rollbar-to-console', []);

RollbarToConsoleModule.service('Rollbar', () => {
  return {
    error: console.error.bind(console),
    warning: console.warn.bind(console),
    info: console.log.bind(console),
    debug: console.log.bind(console)
  };
});

export default RollbarToConsoleModule.name;
