import angular from 'angular';
import uiRouter from 'angular-ui-router';

import 'ng-formio/css/formio.css';
import 'ng-formio/dist/formio-full';
import 'ngGeolocation/ngGeolocation';

import Common from './common/common';

import SmileyFaces from '@kbs/kbs-forms-common/lib/formio-custom/smiley-faces';
import CodeReader from '@kbs/kbs-forms-common/lib/formio-custom/code-reader-component/angular';
import RemoteSelect from '@kbs/kbs-forms-common/lib/formio-custom/remote-select-component';
import IconedRating from '@kbs/kbs-forms-common/lib/formio-custom/iconed-rating';
import FormsCommonUtils from '@kbs/kbs-forms-common/lib/utils';
import Notifications from '@kbs/kbs-forms-common/lib/toasts'
import FormioImage from '@kbs/kbs-forms-common/lib/formio-custom/decorators/formio-image';
import FormUIPostProcess from '@kbs/kbs-forms-common/lib/form-ui-post-process';
import Components from './components/components';
import AppComponent from './app.component';
import RollbarToConsole from './rollbar-to-console';
import Rollbar from './rollbar';

const RollbarModule = process.env.NODE_ENV === 'production' ? Rollbar : RollbarToConsole;

import './app.scss';

angular.module('app', [
  uiRouter,
  Common,
  Components,
  'formio',
  'ngSanitize',
  'app.formioConstants',
  'app.constants',
  'tandibar/ng-rollbar',
  'ui.bootstrap',
  'ngGeolocation',
  SmileyFaces,
  CodeReader,
  RemoteSelect,
  IconedRating,
  FormsCommonUtils,
  Notifications,
  FormioImage,
  FormUIPostProcess,
  RollbarModule
])

.config(function($urlRouterProvider, $locationProvider) {
  'ngInject';
  $urlRouterProvider.otherwise('/');
  $locationProvider.html5Mode(false).hashPrefix('');
  window.formioImagesEndpoint = '/api/image'
})
.run(function($rootScope, $window, Formio) {
  'ngInject';
  const analyticsId = $window.__config.analyticsId;
  if (analyticsId) {
    const firstScript = document.createElement('script');
    firstScript.async = true;
    firstScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    document.head.append(firstScript);

    const secondScript = document.createElement('script');
    secondScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${analyticsId}', {
          send_page_view: false,
        });
      `;
    document.head.append(secondScript);
  } else {
    console.error('No Analytics 4 measurement ID found!');
  }

  $window.gtag('event', 'page_view');

  $rootScope.alerts = [];
  $rootScope.kbsAlerts = [];
  $rootScope.closeAlert = function(index) {
    $rootScope.kbsAlerts.splice(index, 1);
  };
})

.component('app', AppComponent);

fetchData().then(bootstrapApplication);
function fetchData() {
  var initInjector = angular.injector(['ng']);
  var $http = initInjector.get('$http');

  return $http({
    url: 'api/client-setup'
  }).then(function(response) {
    window.__config = response.data.appConfig;
    window.__formio = response.data.formio;
    return response.data;
  });
}

function bootstrapApplication(bootstrapConfig = {}) {
  angular.element(document).ready(function() {
    window.bootstrapConfig = bootstrapConfig;
    angular.module('app.constants', []).constant('appConfig', bootstrapConfig.appConfig);
    angular.module('app.formioConstants', []).constant('AppConfig', {
      'appUrl': window && window.__formio ? window.__formio.appUrl : '',
      'apiUrl': window && window.__formio ? window.__formio.apiUrl : ''
    });
    angular.bootstrap(document, ['app']);
  });
}
